import React from "react"
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import VideosSectionUpdate from "../../components/sections/VideosSectionUpdate";

const PowerSuppliesVideosPage = () => (
  <MainContainer>
    <HeadDataNew 
      title='Power Supplies Videos Page' 
      description='A list of Command Access Power Supplies videos.' 
    />

    <VideosSectionUpdate 
      title="Power Supplies"
      category="Power Supplies"
    />
  </MainContainer>
)

export default PowerSuppliesVideosPage